import React from 'react';
import styled from '@emotion/styled';
import { useQueryParam, StringParam } from 'use-query-params';
import { InlineWidget } from 'react-calendly';

import PageSEO from '@zd/components/page-seo';
import { Container } from '@zd/components/grid';
import ContactForm from '@zd/components/forms/contact-form';
import PageTitle from '@zd/components/page-title';
import BackLink from '@zd/components/back-link';
import theme from '@zd/theme';

const StyledBackLink = styled(BackLink)`
  margin-top: 1.5rem;
  margin-bottom: 3.5rem;
`;

const CalendlyDiv = styled.div`
  height: 850px;

  iframe {
    height: 850px;
    margin: 0;
  }

  ${theme.medias.gteSmall} {
    height: 800px;

    iframe {
      height: 800px;
    }
  }

  ${theme.medias.gteMedium} {
    height: 900px;

    iframe {
      height: 900px;
    }
  }

  ${theme.medias.gteLarge} {
    height: 800px;

    iframe {
      height: 800px;
    }
  }
`;

export default props => {
  const [bookingTypeFromQuery] = useQueryParam('type', StringParam);

  let pageTitle = 'Marcar Consulta';

  if (bookingTypeFromQuery === 'online') {
    pageTitle = 'Marcar Consulta Online';
  } else if (bookingTypeFromQuery === 'presencial') {
    pageTitle = 'Marcar Consulta Presencial';
  }

  return (
    <>
      <PageSEO {...props} title={pageTitle} />
      <Container>
        <PageTitle title={pageTitle} />
        <CalendlyDiv>
          <InlineWidget
            url="https://calendly.com/dr-joao-vilas-boas/consulta-clinica-da-boavista"
            pageSettings={{
              backgroundColor: 'ffffff',
              hideEventTypeDetails: false,
              hideLandingPageDetails: false,
              primaryColor: '3D1E80',
              textColor: '070115',
            }}
          />
        </CalendlyDiv>
        {/*
        <ContactForm
          submitLabel="Submeter marcação"
          descriptionLabel="Notas"
          descriptionPlaceholder="Descreva qual o seu motivo de consulta"
          isBooking={true}
          showWhatsApp={false}
        />
        */}
        <StyledBackLink />
      </Container>
    </>
  );
};
